import React from 'react'
import styled from 'styled-components'
import SEO from '../components/SEO'
import { Box, Flex, Heading, Text, Button } from '../components/UI'
import Layout from '../components/Layout'
import ScrollableLink from '../components/ScrollableLink'

const NotFound = styled(Heading)`
  color: ${props => props.theme.blue};
  font-size: 2rem;
  text-align: center;
`
const Img = styled.img`
  width: 100%;
  height: auto;
`

const NoRoute = () => (
  <Layout>
    <SEO title="404: not found" />
    <Box py={4} minHeight={400}>
      <Flex align="center" flexDirection="column">
      <Box m="2">
        <Img src="/404.png" />
      </Box>
        <Heading textAlign="center" mb={1}>404: Page not found.</Heading>
        <Text fontSize={2} textAlign="center">Sorry!</Text>

        <ScrollableLink to="/">
          <Button variant="secondary" small>
            Go back to the home page
          </Button>
        </ScrollableLink>
      </Flex>
    </Box>
  </Layout>
)

export default NoRoute
